import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Box, Divider, Grid, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import CardWrapper from '../Components/CardWrapper';
import CustomCheckBox from '../Components/CustomCheckBox';
import CustomRadioGroup from '../Components/CustomRadioGroup';
import CreditCard from '../Assets/CreditCard';
import StandardField from '../Components/StandardField';
import Apartment from '../Assets/svg/Apartment';
import MeetingIcon from '../Assets/svg/MeetingIcon';
import CityIcon from '../Assets/svg/CityIcon';
import Badge from '../Assets/svg/Badge';
import CustomBtn from '../Components/CustomBtn';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { formReducer, getDuration } from '../utils/globalFunctions';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { applyCoupon, confirmOrder } from '../Endpoints/UserEndpoints';
import SnackAlert from '../Components/SnackAlert';
import TourIcon from '@mui/icons-material/Tour';
import Loader from '../Components/Loader';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import { loginUser } from '../Redux/Slices/authSlice';
const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [query] = useSearchParams();
    const isSmall = useMediaQuery('(max-width:692px)');
    const { palette: { primary } } = useTheme();
    const user = useSelector(state => state.auth.userData);

    const [type, setType] = useState();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState('');
    const [details, setDetails] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isCardPayment, setIsCardPayment] = useState(false);
    const [formData, setFormData] = useReducer(formReducer, {});
    const paymentDetails = JSON.parse(query.get('payment_info'));
    const checkoutInfo = JSON.parse(localStorage.getItem('bookingDetails'));

    const handlePayNow = async () => {
        setIsSubmitted(true);
        if ((!formData['apartment'] || !formData['streetAddress'] || !formData['city'] || !formData['state'] || !formData['zipCode'] || !formData['country']) && !paymentDetails) {
            return;
        }
        if (formData['payment_type'] === 'card' && !paymentDetails) {
            localStorage.setItem('bookingDetails', JSON.stringify({ ...state, ...formData }));
            window.scrollTo(0, 0);
            setIsCardPayment(true);
        } else {
            setLoading('checkout');
            const billingAddress = {
                appartment: paymentDetails ? checkoutInfo?.apartment : formData['apartment'],
                city: paymentDetails ? checkoutInfo?.city : formData['city'],
                new_same: (paymentDetails ? checkoutInfo?.address_type : formData['address_type']) === 'new_address' ? 'new' : 'same',
                country: paymentDetails ? checkoutInfo?.country : formData['country'],
                state: paymentDetails ? checkoutInfo?.state : formData['state'],
                street: paymentDetails ? checkoutInfo?.streetAddress : formData['streetAddress'],
                zip_code: paymentDetails ? checkoutInfo?.zipCode : formData['zipCode'],
            }
            const userInfo = JSON.parse(localStorage.getItem('User'));
            let userData = {
                ...userInfo,
                billingAddress
            }
            const data = {
                IntakeQuestion: paymentDetails ? checkoutInfo?.bookingDetails?.IntakeQuestion : details?.IntakeQuestion,
                Price: paymentDetails ? checkoutInfo?.Price : state?.Price,
                PurchaseCost: ((paymentDetails ? checkoutInfo?.Price : state?.Price) - (paymentDetails ? checkoutInfo?.discount : formData['discount'] ?? 0)),
                billingAddress,
                bookingDetails: paymentDetails ? checkoutInfo?.bookingDetails : details,
                couponCode: formData['coupon_code'] ?? '',
                discountPrice: paymentDetails ? checkoutInfo?.discount : formData['discount'] ?? 0,
                discountRate: paymentDetails ? checkoutInfo?.discountRate : formData['discountRate'] ?? 0,
                paymentDetails: {
                    payment_mode: formData['payment_type'] ?? '',
                    save_card: false,
                },
                serviceClassId: paymentDetails ? checkoutInfo?.bookingDetails?._id : details?._id,
                slots: paymentDetails ? checkoutInfo?.slots : state.slots
            }
            try {
                const response = await confirmOrder(data);
                if (response?.success === 'Booking is done successfully!') {
                    dispatch(loginUser(userData));
                    localStorage.setItem('User', JSON.stringify(userData));
                    localStorage.removeItem('bookingDetails');
                    navigate('/response?status=success');
                    setLoading('');
                } else {
                    setType('error');
                    setMessage(response?.message ?? 'Error occured please try again!');
                    setShowAlert(true);
                    setLoading('');
                }
            } catch (err) {
                console.log('Err', err)
                setType('error');
                setMessage(err?.data === 'User is not authenticated!' ? 'Session expired please login again!' : 'Error occured please try again!');
                setShowAlert(true);
                setLoading('');
            }
        }
    }

    const handleApplyCoupon = async () => {
        try {
            setLoading('coupon');
            const response = await applyCoupon(formData['coupon_code']);
            if (response?.data?.length > 0) {
                const coupon = response?.data[0];
                if (dayjs(coupon?.ValidUntil).format('MM/DD/YYYY hh:mm:ss A') >= dayjs().format('MM/DD/YYYY hh:mm:ss A')) {
                    const items = [
                        {
                            target: {
                                name: 'discountRate',
                                value: coupon?.Discount
                            }
                        },
                        {
                            target: {
                                name: 'discount',
                                value: (coupon?.Discount / 100) * state?.Price
                            }
                        },
                        {
                            target: {
                                name: 'coupon_code',
                                value: ''
                            }
                        }
                    ]
                    items.forEach(x => setFormData(x));
                    setType('success');
                    setMessage(`Discount applied successfully`);
                    setShowAlert(true);
                } else {
                    setType('error');
                    setMessage(`${formData['coupon_code']} coupon code is expired!`);
                    setShowAlert(true);
                }
                setLoading('');
            } else {
                setType('error');
                setMessage('Invalid coupon!');
                setShowAlert(true);
                setLoading('');
            }
        } catch (error) {
            console.log(error)
            setType('error');
            setMessage('Error occured please try again!');
            setShowAlert(true);
            setLoading('');
        }
    }

    useEffect(() => {
        const items = [
            {
                target: {
                    name: 'payment_type',
                    value: 'card'
                }
            },
            {
                target: {
                    name: 'address_type',
                    value: 'same_as_permanent'
                }
            }
        ]
        items.forEach(x => setFormData(x));
    }, [])

    useEffect(() => {
        if (!paymentDetails?.RESPCODE) {
            const isPrevious = formData['address_type'] === 'same_as_permanent';
            const items = [
                {
                    target: {
                        name: 'apartment',
                        value: isPrevious ? user?.billingAddress?.appartment : ''
                    }
                },
                {
                    target: {
                        name: 'city',
                        value: isPrevious ? user?.billingAddress?.city : ''
                    }
                },
                {
                    target: {
                        name: 'streetAddress',
                        value: isPrevious ? user?.billingAddress?.street : ''
                    }
                },
                {
                    target: {
                        name: 'state',
                        value: isPrevious ? user?.billingAddress?.state : ''
                    }
                },
                {
                    target: {
                        name: 'zipCode',
                        value: isPrevious ? user?.billingAddress?.zip_code : ''
                    }
                },
                {
                    target: {
                        name: 'country',
                        value: isPrevious ? user?.billingAddress?.country : ''
                    }
                },
            ]
            items.forEach(x => setFormData(x));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData['address_type']])

    useEffect(() => {
        if (paymentDetails?.RESPCODE) {
            setDetails(checkoutInfo?.bookingDetails);
            const items = [
                {
                    target: {
                        name: 'apartment',
                        value: checkoutInfo?.apartment
                    }
                },
                {
                    target: {
                        name: 'city',
                        value: checkoutInfo?.city
                    }
                },
                {
                    target: {
                        name: 'streetAddress',
                        value: checkoutInfo?.streetAddress
                    }
                },
                {
                    target: {
                        name: 'state',
                        value: checkoutInfo?.state
                    }
                },
                {
                    target: {
                        name: 'zipCode',
                        value: checkoutInfo?.zipCode
                    }
                },
                {
                    target: {
                        name: 'country',
                        value: checkoutInfo?.country
                    }
                },
                {
                    target: {
                        name: 'address_type',
                        value: checkoutInfo?.address_type
                    }
                },
                {
                    target: {
                        name: 'payment_type',
                        value: checkoutInfo?.payment_type
                    }
                },
                {
                    target: {
                        name: 'discountRate',
                        value: checkoutInfo?.discountRate
                    }
                },
                {
                    target: {
                        name: 'discount',
                        value: (checkoutInfo?.discount / 100) * checkoutInfo?.Price
                    }
                }
            ]
            items.forEach(x => setFormData(x));
            if (paymentDetails?.RESPCODE === "1") {
                handlePayNow();
            } else {
                navigate('/response?status=failed');
            }
        } else {
            setDetails(state?.bookingDetails);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <>
            {isCardPayment ?
                <SadadComponent paymentDetails={state} paymentType={formData['payment_type']} />
                :
                <Box
                    sx={{
                        m: { lg: "100px 116px", md: '80px', sm: '30px 25px', xs: '15px' },
                    }}
                >
                    <Grid container spacing={{ lg: 4, xs: 2 }}>
                        <Grid item sm={6} xs={12}>
                            <CardWrapper title={details?.Name ?? '---'}>
                                <Box
                                    sx={{
                                        borderRadius: '0px 5px 0px 0px',
                                        background: '#FF9F2F',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        p: { xs: '4px 26px' }
                                    }}
                                >
                                    <Typography color={primary.main} fontSize={{ lg: 14, xs: 12 }} fontWeight={700}>
                                        TRAINING
                                    </Typography>
                                </Box>
                                <Typography color={'#15192C'} fontSize={{ lg: 22, sm: 18, xs: 16 }} fontWeight={500} mt={{ lg: '20px', xs: '15px' }}>
                                    {details?.facilitiesDetails?.[0]?.Name}
                                </Typography>
                                <Typography color={'#707070'} fontSize={{ sm: 16, xs: 14 }} fontWeight={700} mt={'10px'}>
                                    {details?.Address}
                                </Typography>
                                <Typography color={'#15192C'} fontSize={{ lg: 22, sm: 18, xs: 16 }} fontWeight={700} mt={{ sm: '20px', xs: '15px' }}>
                                    Service - {details?.categories?.[0]?.Category}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        mt: '10px',
                                    }}
                                >
                                    {details?.amenities?.map(x => (
                                        <CustomCheckBox
                                            key={x?.name}
                                            label={x?.name ?? ''}
                                            checked={true}
                                            checkBoxStyle={{
                                                'span': {
                                                    fontSize: { lg: 16, xs: 14 },
                                                    p: { lg: '9px', xs: '5px' }
                                                },
                                                'svg': {
                                                    width: { lg: '1em', xs: '20px' },
                                                    height: { lg: '1em', xs: '20px' }
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Box
                                    sx={{
                                        maxHeight: { lg: '230px', md: '270px', sm: isSmall ? '170px' : '229px', xs: '230px', },
                                        overflow: 'auto'
                                    }}
                                >
                                    {(checkoutInfo ?? state)?.slots && Object.keys((checkoutInfo ?? state)?.slots)?.map((x, i) => (
                                        <Box
                                            key={i}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                borderRadius: '5px',
                                                mt: { md: '20px', xs: '10px' },
                                                background: primary.darkBlue,
                                                p: { md: '10px 15px', sm: '6px 4px', xs: '10px' },
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box component={'div'} textAlign={'center'}>
                                                <Typography color={primary.main} fontSize={{ lg: 14, md: 14, xs: 12 }} fontWeight={{ md: 700, sm: 500, xs: 700 }}>
                                                    DAY
                                                </Typography>
                                                <Typography color={primary.main} fontSize={{ lg: 18, md: 16, xs: 14 }} fontWeight={{ md: 700, sm: 600, xs: 700 }}>
                                                    {dayjs(x).format('dddd')}
                                                </Typography>
                                            </Box>
                                            <Box component={'div'} textAlign={'center'}>
                                                <Typography color={primary.main} fontSize={{ lg: 14, md: 14, xs: 12 }} fontWeight={{ md: 700, sm: 500, xs: 700 }}>
                                                    Total Hours
                                                </Typography>
                                                <Typography color={primary.main} fontSize={{ lg: 18, md: 16, xs: 14 }} fontWeight={{ md: 700, sm: 600, xs: 700 }}>
                                                    {getDuration((checkoutInfo ?? state)?.slots)}
                                                </Typography>
                                            </Box>
                                            <Box component={'div'} textAlign={'center'}>
                                                <Typography color={primary.main} fontSize={{ lg: 14, md: 14, xs: 12 }} fontWeight={{ md: 700, sm: 500, xs: 700 }}>
                                                    Booking Price
                                                </Typography>
                                                <Typography color={primary.main} fontSize={{ lg: 18, md: 16, xs: 14 }} fontWeight={{ md: 700, sm: 600, xs: 700 }}>
                                                    QR{((paymentDetails ? checkoutInfo : state)?.slots[x]?.length * details?.Price)?.toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </CardWrapper>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CardWrapper title={'Payment Options'}>
                                <Typography color={'#707070'} fontSize={{ sm: 14, xs: 12 }} mb={{ lg: '20px', xs: '10px' }}>
                                    Please choose your payment method and payment type.
                                </Typography>
                                <CustomRadioGroup
                                    name='payment_type'
                                    value={formData['payment_type']}
                                    onChange={setFormData}
                                    options={[{ label: 'Card Payment', value: 'card' }]}
                                    radioStyle={{
                                        'span': {
                                            fontSize: { sm: 14, xs: 12 },
                                        },
                                        '.MuiRadio-colorPrimary': {
                                            p: { lg: '9px', xs: '5px' }
                                        },
                                        'svg': {
                                            width: { lg: '1em', xs: '20px' },
                                            height: { lg: '1em', xs: '20px' }
                                        }
                                    }}
                                />
                            </CardWrapper>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CardWrapper title={'Billing Address'}>
                                <Box
                                    sx={{
                                        mt: 2
                                    }}
                                >
                                    <CustomRadioGroup
                                        name='address_type'
                                        value={formData['address_type']}
                                        onChange={setFormData}
                                        options={[{ label: 'Same as  Permanent', value: 'same_as_permanent' }, { label: 'New Address', value: 'new_address' }]}
                                        radioStyle={{
                                            'span': {
                                                fontSize: { sm: 14, xs: 12 },
                                            },
                                            '.MuiRadio-colorPrimary': {
                                                p: { lg: '9px', xs: '5px' }
                                            },
                                            'svg': {
                                                width: { lg: '1em', xs: '20px' },
                                                height: { lg: '1em', xs: '20px' }
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '20px' },
                                        mt: { md: '20px', xs: '16px' }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '30px', xs: '25px' },
                                                width: { lg: '30px', xs: '25px' },
                                            }
                                        }}
                                    >
                                        <Apartment />
                                    </Box>
                                    <StandardField
                                        placeholder='Apartment'
                                        name='apartment'
                                        value={formData['apartment']}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        required={true}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '20px' },
                                        mt: { md: '20px', xs: '16px' }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '30px', xs: '25px' },
                                                width: { lg: '30px', xs: '25px' },
                                            }
                                        }}
                                    >
                                        <MeetingIcon />
                                    </Box>
                                    <StandardField
                                        placeholder='Street Address'
                                        name='streetAddress'
                                        value={formData['streetAddress']}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        required={true}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '20px' },
                                        mt: { md: '20px', xs: '16px' }
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '30px', xs: '25px' },
                                                width: { lg: '30px', xs: '25px' },
                                            }
                                        }}
                                    >
                                        <CityIcon />
                                    </Box>
                                    <StandardField
                                        placeholder='City'
                                        name='city'
                                        value={formData['city']}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        required={true}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '20px' },
                                        mt: { md: '20px', xs: '16px' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '30px', xs: '25px' },
                                                width: { lg: '30px', xs: '25px' },
                                            }
                                        }}
                                    >
                                        <Badge />
                                    </Box>
                                    <StandardField
                                        placeholder='Zip code'
                                        name='zipCode'
                                        value={formData['zipCode']}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        required={true}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '20px' },
                                        mt: { md: '20px', xs: '16px' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '30px', xs: '25px' },
                                                width: { lg: '30px', xs: '25px' },
                                            }
                                        }}
                                    >
                                        <TourIcon
                                            sx={{
                                                color: '#A8A8A8'
                                            }}
                                        />
                                    </Box>
                                    <StandardField
                                        placeholder='Country'
                                        name='country'
                                        value={formData['country']}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        required={true}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '20px' },
                                        mt: { md: '20px', xs: '16px' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '30px', xs: '25px' },
                                                width: { lg: '30px', xs: '25px' },
                                            }
                                        }}
                                    >
                                        <OtherHousesIcon
                                            sx={{
                                                color: '#A8A8A8'
                                            }}
                                        />
                                    </Box>
                                    <StandardField
                                        placeholder='State'
                                        name='state'
                                        value={formData['state']}
                                        onChange={setFormData}
                                        isSubmitted={isSubmitted}
                                        required={true}
                                    />
                                </Box>
                            </CardWrapper>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CardWrapper title={'Order Summary'}>
                                <Typography color={'#707070'} fontSize={{ lg: 18, sm: 16, xs: 14 }} fontWeight={500} mt={2}>
                                    Coupon Code
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { lg: '30px', xs: '15px' },
                                        mt: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: { lg: '30px', xs: '25px' },
                                            textAlign: 'center',
                                            'svg': {
                                                minWidth: { lg: '27px', xs: '25px' },
                                                width: { lg: '27px', xs: '25px' },
                                                mb: '-7px'
                                            }
                                        }}
                                    >
                                        <CreditCard />
                                    </Box>
                                    <StandardField
                                        placeholder='Coupon Code'
                                        name='coupon_code'
                                        value={formData['coupon_code']}
                                        onChange={setFormData}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><HelpOutlineIcon sx={{ color: primary.darkBlue, cursor: 'pointer', fontSize: { lg: 24, xs: 18 } }} /></InputAdornment>,
                                        }}
                                    />
                                    <CustomBtn
                                        loading={loading === 'coupon'}
                                        disabled={!formData['coupon_code']}
                                        title='Apply'
                                        btnStyle={{
                                            borderRadius: '26px',
                                            background: primary.darkBlue,
                                            fontWeight: 500,
                                            textTransform: 'capitalize',
                                            fontSize: { lg: 16, xs: 14 },
                                            p: { lg: '7px 35px', xs: '5px 25px' }
                                        }}
                                        onClick={handleApplyCoupon}
                                    />
                                </Box>
                                <Box component={'div'} mt={{ lg: '40px', xs: '25px' }}>
                                    <Box
                                        component={'div'}
                                        sx={{
                                            gap: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={600}>
                                            Purchase Cost
                                        </Typography>
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={500}>
                                            QR{((paymentDetails ? checkoutInfo : state)?.Price)?.toFixed(2)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        component={'div'}
                                        sx={{
                                            mt: { lg: '20px', xs: '15px' },
                                            gap: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={600}>
                                            Transaction Changes
                                        </Typography>
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={500}>
                                            QR 00.0
                                        </Typography>
                                    </Box>
                                    <Box
                                        component={'div'}
                                        sx={{
                                            mt: { lg: '20px', xs: '15px' },
                                            gap: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={600}>
                                            Commision
                                        </Typography>
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={500}>
                                            QR 00.0
                                        </Typography>
                                    </Box>
                                    <Box
                                        component={'div'}
                                        sx={{
                                            mt: { lg: '20px', xs: '15px' },
                                            gap: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={600}>
                                            Discount Applied (%)
                                        </Typography>
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={500}>
                                            {(formData['discountRate'] ? formData['discountRate'] : 0).toFixed(2)}%
                                        </Typography>
                                    </Box>
                                    <Box
                                        component={'div'}
                                        sx={{
                                            mt: { lg: '20px', xs: '15px' },
                                            mb: { lg: '30px', xs: '15px' },
                                            gap: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={600}>
                                            Discount (Amount)
                                        </Typography>
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={500}>
                                            QR {(formData['discount'] ? formData['discount'] : 0).toFixed(2)}
                                        </Typography>
                                    </Box>
                                    <Divider light />
                                    <Box
                                        component={'div'}
                                        sx={{
                                            mt: { lg: '20px', xs: '15px' },
                                            gap: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography color={'#707070'} fontSize={{ lg: 16, xs: 14 }} fontWeight={600}>
                                            Total Cost
                                        </Typography>
                                        <Typography color={primary.darkBlue} fontSize={{ lg: 18, xs: 16 }} fontWeight={700} component={'span'}>
                                            QR &nbsp;
                                            <Typography color={primary.darkBlue} fontSize={{ lg: 16, xs: 14 }} fontWeight={700} component={'span'}>
                                                {((paymentDetails ? checkoutInfo : state)?.Price - (formData['discount'] ?? 0))?.toFixed(2)}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardWrapper>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: { lg: '100px', xs: '50px' },
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        <CustomBtn
                            title='Pay Now!'
                            loading={loading === 'checkout'}
                            onClick={handlePayNow}
                            btnStyle={{
                                fontSize: { lg: 20, sm: 16, xs: 14 },
                                borderRadius: '26px',
                                background: primary.darkBlue,
                                fontWeight: 700,
                                textTransform: 'capitalize',
                                px: { lg: '100px', xs: '50px' }
                            }}
                        />
                    </Box>
                </Box>
            }
            <SnackAlert
                type={type}
                open={showAlert}
                message={message}
                handleClose={() => setShowAlert(false)}
            />
        </>
    )
}

export default Checkout

const SadadComponent = ({ paymentDetails, paymentType }) => {
    const formRef = useRef("");
    const user = useSelector(state => state.auth.userData);
    const [isLoaded, setIsLoaded] = useState(false);

    const formData = {
        merchant_id: process.env.REACT_APP_SADAD_MERCHANT_ID,
        secretKey: process.env.REACT_APP_SADAD_MERCHANT_SECRETKEY,
        WEBSITE: process.env.REACT_APP_SADAD_REGISTERED_WEBSITE,
        CALLBACK_URL: process.env.REACT_APP_SADAD_CALLBACK_URL,
        ORDER_ID: paymentDetails?.bookingDetails?._id,
        TXN_AMOUNT: paymentDetails?.Price,
        CUST_ID: user?._id,
        EMAIL: user?.email,
        MOBILE_NO: user?.phone,
        SADAD_WEBCHECKOUT_PAGE_LANGUAGE: "ENG",
        SADAD_WEBCHECKOUT_HIDE_LOADER: "NO",
        Showdialog: "1",
        txnDate: new Date().toISOString(),
        productdetail_order_id: paymentDetails?.bookingDetails?.Facility,
        productdetail_itemname: paymentDetails?.bookingDetails?.Name,
        productdetail_amount: paymentDetails?.bookingDetails?.Price,
        productdetail_quantity: paymentDetails?.noOfPeople,
        productdetail_type: paymentDetails?.bookingDetails?.ClassType,
    };


    useEffect(() => {
        setIsLoaded(false);
        if (paymentType === 'card') {
            formRef?.current?.submit()
        }
    }, [paymentType])

    return (
        <Box>
            <form action="https://secure.sadadqa.com/webpurchasepage" target="onlyiframe" method="post" id="paymentform" name="paymentform" data-link="https://secure.sadadqa.com/webpurchasepage" ref={formRef} >
                <input type="hidden" name="merchant_id" id="merchant_id" value={formData.merchant_id} />
                <input type="hidden" name="secret_key" id="secret_key" value={formData.secretKey} />
                <input type="hidden" name="ORDER_ID" id="ORDER_ID" value={formData.ORDER_ID} />
                <input type="hidden" name="WEBSITE" id="WEBSITE" value={formData.WEBSITE} />
                <input type="hidden" name="TXN_AMOUNT" id="TXN_AMOUNT" value={formData.TXN_AMOUNT} />
                <input type="hidden" name="CUST_ID" id="CUST_ID" value={formData.CUST_ID} />
                <input type="hidden" name="EMAIL" id="EMAIL" value={formData.EMAIL} />
                <input type="hidden" name="MOBILE_NO" id="MOBILE_NO" value={formData.MOBILE_NO} />
                <input type="hidden" name="SADAD_WEBCHECKOUT_HIDE_LOADER" id="SADAD_WEBCHECKOUT_HIDE_LOADER" value={formData.SADAD_WEBCHECKOUT_HIDE_LOADER} />
                <input type="hidden" name="Showdialog" id="Showdialog" value={formData.Showdialog} />
                <input type="hidden" name="SADAD_WEBCHECKOUT_PAGE_LANGUAGE" id="SADAD_WEBCHECKOUT_PAGE_LANGUAGE" value={formData.SADAD_WEBCHECKOUT_PAGE_LANGUAGE} />
                <input type="hidden" name="CALLBACK_URL" id="CALLBACK_URL" value={formData.CALLBACK_URL} />
                <input type="hidden" name="txnDate" id="txnDate" value={formData.txnDate} />
                <input type="hidden" name="productdetail[0][order_id]" value={formData.productdetail_order_id} />
                <input type="hidden" name="productdetail[0][itemname]" value={formData.productdetail_itemname} />
                <input type="hidden" name="productdetail[0][amount]" value={formData.productdetail_amount} />
                <input type="hidden" name="productdetail[0][quantity]" value={formData.productdetail_quantity || 1} />
                <input type="hidden" name="productdetail[0][type]" value={formData.productdetail_type} />
            </form>
            {paymentType === 'card' &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        minHeight: { sm: '76vh', xs: '730px' }
                    }}
                >
                    {!isLoaded && <Loader />}
                    <iframe
                        title="2"
                        name="onlyiframe"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                            display: isLoaded ? 'flex' : 'none',
                            width: '100%',
                            flexDirection: 'column',
                            flex: 1
                        }}
                        id="onlyiframe"
                        border="0"
                        className="not_hide_sadad"
                        onLoad={() => setIsLoaded(true)}
                    />
                </Box>

            }
        </Box>
    );
};