import { Badge, Box, useMediaQuery, useTheme } from '@mui/material';
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react'

const Calendar = ({ value, onChange, disableHighlightToday = true, slots = [], selectedSlots = [], readOnly = false }) => {
    const isSmall = useMediaQuery('(max-width:430px)');
    const { palette: { primary } } = useTheme();

    return (
        <Box
            component={'span'}
            sx={{
                display: 'block',
                width: { sm: 'fit-content', xs: '100%' },
                '.MuiDateCalendar-root': {
                    width: { sm: 'fit-content', xs: '100%' },
                    height: 'auto',
                    maxHeight: 'max-content',
                },
            }}
        >
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
            >
                <DateCalendar
                    minDate={dayjs()}
                    disableHighlightToday={disableHighlightToday}
                    value={value}
                    onChange={(val) => onChange(dayjs(val).format('MM/DD/YYYY'))}
                    dayOfWeekFormatter={(_day, date) => dayjs(date).format('ddd')}
                    slots={{
                        day: ServerDay,
                    }}
                    slotProps={{
                        day: {
                            selectedSlots,
                            readOnly,
                            slots,
                        },
                    }}
                    sx={{
                        '.MuiPickersCalendarHeader-root': {
                            position: 'relative',
                        },
                        '.MuiPickersCalendarHeader-labelContainer': {
                            margin: 'auto',
                            color: "#15192C",
                            fontSize: isSmall ? 14 : 16,
                        },
                        '.MuiPickersCalendarHeader-label': {
                            mr: isSmall ? 0 : '6px'
                        },
                        '.MuiPickersArrowSwitcher-spacer': {
                            display: 'none'
                        },
                        '.MuiDayCalendar-weekDayLabel': {
                            p: isSmall ? '0 18px' : '0 25px',
                            color: '#6E7781',
                        },
                        '.MuiIconButton-edgeEnd': {
                            position: 'absolute',
                            left: 0
                        },
                        '.MuiIconButton-root': {
                            color: '#15192C'
                        },
                        '.MuiPickersDay-root': {
                            backgroundColor: `#E4EEF5 !important`,
                            p: isSmall ? '18px' : '25px',
                            borderRadius: 0,
                            color: '#262D33 !important'
                        },
                        '.Mui-selected': {
                            backgroundColor: `${primary.darkBlue} !important`,
                            color: `${primary.main} !important`
                        },
                        '.Mui-disabled:not(.Mui-selected)': {
                            color: 'rgba(0, 0, 0, 0.38) !important'
                        },
                        '.MuiPickersSlideTransition-root': {
                            minHeight: '262px'
                        }
                    }}
                />
            </LocalizationProvider>
        </Box>
    )
}

const ServerDay = (props) => {
    const { slots = [], day, selectedSlots, readOnly, outsideCurrentMonth, ...other } = props;
    const currentDateSlots = slots?.filter(x => dayjs(x.date).format('MM/DD/YYYY') === dayjs(day).format('MM/DD/YYYY'))[0];
    const isDisabled = currentDateSlots?.time?.some(x => x.disabled)
    const { palette: { primary } } = useTheme();

    const badgeContent = () => {
        const haveSlots = !outsideCurrentMonth && currentDateSlots?.time?.length > 0;
        if ((haveSlots && day.format('MM/DD/YYYY') >= dayjs().format('MM/DD/YYYY'))) {
            return <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '100%',
                    backgroundColor: selectedSlots[dayjs(day).format('MM/DD/YYYY')]?.length > 0 ? '#cb0000' : isDisabled ? 'yellow' : primary.lightBlue,
                }}
            />
        }
        if (!outsideCurrentMonth && readOnly) {
            return <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '100%',
                    backgroundColor: '#cb0000',
                    display: selectedSlots[dayjs(day).format('MM/DD/YYYY')]?.length > 0 ? 'block' : 'none'
                }}
            />
        }

    }
    return (
        <Badge
            overlap="circular"
            key={day.toString()}
            badgeContent={badgeContent()}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} disabled={readOnly ? !selectedSlots[dayjs(day).format('MM/DD/YYYY')] : other.disabled} />
        </Badge>
    );
}



export default Calendar