import { AppBar, Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Assets/svg/Logo';
import CustomBtn from './CustomBtn';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { url } from '../utils/Host';
import { profileOptions } from '../utils/constants';
import { getAllCategories } from '../Endpoints/UserEndpoints';
import { setCategoriesList } from '../Redux/Slices/authSlice';

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { palette: { primary } } = useTheme();
    const matches = useMediaQuery('(max-width:1365px)');
    const user = useSelector(state => state.auth.userData);
    const categoriesList = useSelector(state => state.auth.categoriesList);

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);
    const [mobileOpen, setMobileOpen] = useState(false);

    const drawerWidth = 240;
    const open = Boolean(anchorEl);

    const navItems = [
        {
            link: 'Home',
            href: '/',
            isActive: window.location.pathname === '/'
        },
        {
            link: 'Categories',
            href: '/categories',
            isActive: false
        },
        {
            link: 'Support',
            href: '/#support',
            isActive: false
        }
    ];

    const handleClick = (event, options) => {
        setAnchorEl(event.currentTarget);
        setMenuOptions(options);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = (value) => {
        setMobileOpen(value);
    };

    const getCategoriesList = async () => {
        try {
            const response = await getAllCategories();
            dispatch(setCategoriesList(response?.data ?? []));
            localStorage.setItem('categories', JSON.stringify(response?.data));
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        const categories = JSON.parse(localStorage.getItem('categories'));
        if (!categories) {
            getCategoriesList();
        } else if (categoriesList?.length === 0) {
            dispatch(setCategoriesList(categories ?? []));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <AppBar
                component="nav"
                position="sticky"
            >
                <Toolbar
                    sx={{
                        p: { xs: '10px 26px !important', md: matches ? '20px 26px !important' : `20px 173px !important` },
                        gap: { xs: '20px', md: '50px' },
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            flexGrow: 1,
                            textAlign: 'left',
                            'svg': {
                                cursor: 'pointer',
                                width: { xs: '150px', sm: '206px' }
                            }
                        }}
                    >
                        <Box
                            component={'span'}
                            onClick={() => navigate('/')}
                        >
                            <Logo />
                        </Box>
                    </Box>
                    {user &&
                        <Box
                            sx={{
                                display: { md: 'none', xs: 'flex', },
                                alignItems: 'center',
                                gap: { lg: '10px', xs: '5px' }
                            }}
                        >
                            <Avatar
                                onClick={(e) => handleClick(e, profileOptions)}
                                src={`${url}/profilePicCustomer/${user?.profilePic}`}
                                sx={{
                                    bgcolor: primary.lightBlue,
                                    width: { lg: '50px', xs: '45px' },
                                    height: { lg: '50px', xs: '45px' }
                                }}
                                alt={user?.firstName[0]}
                            />

                            <Box
                                component={'div'}
                                sx={{
                                    display: { sm: 'block', xs: 'none' }
                                }}
                            >
                                <Typography fontSize={12}>
                                    {user?.firstName} {user?.lastName[0]}
                                </Typography>
                                <Typography fontSize={12}>
                                    {user?.email}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => handleDrawerToggle(true)}
                        sx={{ m: 0, p: 0, display: { md: 'none' } }}
                    >
                        <MenuIcon
                            sx={{
                                width: '2rem',
                                height: '2rem',
                                color: primary.darkBlue
                            }}
                        />
                    </IconButton>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            gap: '20px',
                            alignItems: 'center',
                            '.nav_link': {
                                p: matches ? '8px 15px' : '5px 10px',
                                fontSize: '14px'
                            }
                        }}
                    >
                        {navItems.map((item) => (
                            <Box
                                component={'span'}
                                key={item.link}
                            >
                                {item.link === 'Categories' ?
                                    <CustomBtn
                                        onClick={(e) => handleClick(e, categoriesList)}
                                        endIcon={<ArrowDropDownIcon />}
                                        title={item.link}
                                        btnStyle={{
                                            background: 'transparent',
                                            border: 0,
                                            boxShadow: 'none',
                                            color: '#202020',
                                            fontWeight: 600,
                                            p: matches ? '8px 15px' : '5px 10px',
                                            fontSize: '14px',
                                            ':hover': {
                                                background: 'transparent',
                                                border: 0,
                                                boxShadow: 'none',
                                                color: '#202020',
                                            },
                                            ':active': {
                                                boxShadow: 'none',
                                            }
                                        }}
                                    />
                                    :
                                    <NavLink
                                        to={item.href}
                                        className={() => `nav_link ${item.isActive ? "active" : ""}`}
                                        onClick={() => item.link === 'Categories' ? handleClick() : null}
                                    >
                                        {item.link}
                                    </NavLink>
                                }
                            </Box>
                        ))}
                        {user ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: { lg: '10px', xs: '5px' }
                                }}
                            >
                                <Avatar
                                    onClick={(e) => handleClick(e, profileOptions)}
                                    src={`${url}/profilePicCustomer/${user?.profilePic}`}
                                    sx={{
                                        bgcolor: primary.lightBlue,
                                        width: { lg: '50px', xs: '45px' },
                                        height: { lg: '50px', xs: '45px' }
                                    }}
                                    alt={user?.firstName[0]}
                                />

                                <Box
                                    component={'div'}

                                >
                                    <Typography fontSize={12}>
                                        {user?.firstName} {user?.lastName[0]}
                                    </Typography>
                                    <Typography fontSize={12}>
                                        {user?.email}
                                    </Typography>
                                </Box>
                            </Box>
                            :
                            <>
                                <CustomBtn
                                    onClick={() => navigate('/login')}
                                    title='LOG IN'
                                    variant='outlined'
                                    btnStyle={{
                                        p: matches ? '5px 10px' : '4px 25px',
                                        fontSize: '14px'
                                    }}
                                />
                                <CustomBtn
                                    onClick={() => navigate('/register')}
                                    title='SIGN UP'
                                    btnStyle={{
                                        p: matches ? '5px 10px' : '4px 25px',
                                        fontSize: '14px'
                                    }}
                                />
                            </>
                        }
                    </Box>
                </Toolbar>
            </AppBar >
            <nav>
                <Drawer
                    open={mobileOpen}
                    onClose={() => handleDrawerToggle(false)}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            <Logo width="162" />
                        </Typography>
                        <Divider />
                        <List>
                            {navItems.map((item) => (
                                <Box
                                    component={'div'}
                                    key={item.link}
                                >
                                    {item.link === 'Categories' ?
                                        <CustomBtn
                                            onClick={(e) => handleClick(e, categoriesList)}
                                            endIcon={<ArrowDropDownIcon />}
                                            title={item.link}
                                            btnStyle={{
                                                background: 'transparent',
                                                border: 0,
                                                width: '100%',
                                                boxShadow: 'none',
                                                color: '#202020',
                                                fontWeight: open ? 800 : 600,
                                                p: '10px 20px',
                                                fontSize: '16px',
                                                mb: '20px',
                                                justifyContent: 'start',
                                                ':active': {
                                                    boxShadow: 'none',
                                                },
                                                ':hover': {
                                                    background: 'transparent',
                                                    border: 0,
                                                    boxShadow: 'none',
                                                    color: '#202020',
                                                }
                                            }}
                                        />
                                        :
                                        <ListItem key={item.link} disablePadding onClick={() => handleDrawerToggle(false)}>
                                            <NavLink
                                                to={item.href}
                                                className={() => `sidebar nav_link ${item?.isActive ? "active" : ""}`}
                                            >
                                                <ListItemText primary={item.link} />
                                            </NavLink>
                                        </ListItem>
                                    }
                                </Box>
                            ))}
                            {!user &&
                                <>
                                    <ListItem>
                                        <CustomBtn
                                            onClick={() => { navigate('/login'); handleDrawerToggle(false); }}
                                            title='LOG IN'
                                            variant='outlined'
                                            btnStyle={{
                                                width: '100%',
                                                p: matches ? '5px 10px' : '4px 25px',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <CustomBtn
                                            onClick={() => { navigate('/register'); handleDrawerToggle(false); }}
                                            title='SIGN UP'
                                            btnStyle={{
                                                width: '100%',
                                                p: matches ? '5px 10px' : '4px 25px',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </ListItem>
                                </>
                            }
                        </List>
                    </Box>
                </Drawer>
            </nav>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiMenu-paper': {
                        width: '200px'
                    },
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor: primary.darkBlue,
                        color: primary.main
                    },
                    '& .MuiMenu-list': {
                        py: { sm: '8px', xs: 0 }
                    },
                    '& li': {
                        fontSize: { sm: 16, xs: 14 },
                        p: { sm: '6px 16px', xs: '10px' },
                        minHeight: 'auto'
                    }
                }}
            >
                {menuOptions.map(x => <MenuItem key={x?._id ?? x?.key} value={x?._id ?? x?.key} onClick={() => { if (x?._id) { navigate(`/category/${x?.Category}`); handleDrawerToggle(false); } else if (x?.route) { navigate(x?.route) } else { x?.onClick() } handleClose() }}>{x?.Category ?? x?.value}</MenuItem>)}
            </Menu>
        </>
    )
}

export default Header